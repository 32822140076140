<template>
    <div>
        <div v-if="cart.length"  class="border">
            <div class="summary">         
                <div v-if="cart.length" >

                    <div class="product-cart" >
                        <div class="product-cart-img">                    
                            <img :src="imgProduct" :alt="`Photo ${cart[0].name}`" @error="imgAlternativo" >               
                        </div>
                        <div class="product-cart-detail">
                            <div class="mb-1"> <b>{{ cart[0].name }}</b> </div>  
                            <div class="short-description"> {{ productDescription.replace(/<\/?[^>]+>/gi, ' ') }} </div>
                        </div>
                    </div>                                        
                    <div class="item-content">
                        <div class="date">
                            <b>{{ $t('checkout.date') }}</b>
                            <span class="date__span">{{ dateFormatted }}</span>
                        </div>                    
                    </div> 
                    <div class="item-content">                
                            <div class="guests-input">
                                <!-- <label class="pax_label">No. Persons</label>                                       -->
                                <div class="total-guests">                
                                    <span> {{ $t('checkout.totalGuest') }} </span>                                                
                                    <button class="badge-guests" type="button" :disabled="true" > {{totalGuest}} </button>
                                </div>                    
                            </div>
                            <div class="pax-summary" v-if="!isAGroup">  
                                <div class="pax-summary__content">
                                    <span> {{ $tc('products.adults', queryParams.adults, { count: queryParams.adults } ) }} </span>
                                    <span>${{ totalBreakdown(cart[0]).totalAdults }} USD</span>
                                </div>      
                                <div class="pax-summary__content" v-if="queryParams.children > 0 && detailChild">
                                    <span> {{ $tc('products.children', queryParams.children, { count: queryParams.children } ) }}  </span>
                                    <span>${{totalBreakdown(cart[0]).totalChildren }} USD</span>
                                </div>             
                            </div>  
                            <div class="pax-summary" v-if="isAGroup">
                                <div class="pax-summary__content">
                                    <span>  Group </span>
                                    <span>${{  breakdown.total.toFixed(2) }} USD</span>
                                </div>  
                            </div>                                                                         
                    </div>  
                    
                    <div class="item-content-footer">
                        <div class="item-content-footer__total">
                            <span> {{ $t('checkout.total') }} *</span>           
                            <span>$ {{ breakdown.total.toFixed(2) }} USD </span>           
                        </div>
                        <span class="tax-text">* {{ $t('checkout.taxesAreIncluded') }} </span>
                    </div>                                                   
                </div>                      
            </div>    
        </div>
        <b-alert show variant="danger"  class="mt-2" v-else>
          <div class="alert-body text-center"> 😣 <span>{{ $t('checkout.youHaveNotSelectedAnyItem') }}</span></div>
        </b-alert>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex'
    import SearchIcon from '@/modules/shop/components/icons/SearchIcon'
    import flatPickr from 'vue-flatpickr-component'
    
    import { toDecimal, addDaysToCurrentDate } from '@/helpers/helpers'
    import { market } from '@/modules/shop/mixins/market'
    

    export default {
        mixins: [ market ], 
        components:{
            flatPickr,
            SearchIcon,            
        },
        data() {
            return {
                imgUrl: process.env.VUE_APP_BASE_URL_SERVER,                
            }
        },
        computed: {             
            ...mapState('auth',['queryParams']),
            ...mapState('products',['productDetail']),                           
            ...mapState('shop',['customer','cart', 'breakdown']),                           
            ...mapState('start',['languages']),                           
            imgProduct(){
                let img
                if(this.cart[0].modelType=="rates"){
                    img=`${process.env.VUE_APP_IMGEXP_SRC_API}${this.cart[0].coverImage}`
                }else{
                    img= `${process.env.VUE_APP_BASE_URL_SERVER}${this.cart[0].coverImage}`
                }
                return img;
            },
            languageName(){
                let name = ''
                if(this.customer.language != 0){
                    const language  = this.languages.find( language => language.id === this.customer.language)
                    name = language ? language.name : ''
                }
                return name
            },
            dateConfig(){
                return {
                    // mode: "range",
                    minDate: addDaysToCurrentDate(),
                    maxDate: '',
                }
            }, 
            totalGuest()  {
                return parseFloat(this.queryParams.adults) + parseFloat(this.queryParams.children)
            },  
            detailAdult(){
                return this.cart.find( item => item.typePax == 'adult')
            },
            detailChild(){
                return this.cart.find( item => item.typePax == 'child')
            },
            productDescription(){
                const item = {...this.cart[0] } //primer elemento del cart
                var txt = document.createElement("textarea");
                txt.innerHTML = item.description 
                return txt.value;
            },
            isAGroup(){
                const adultDetail = this.cart.find( item => item.typeDisplay === 'group' )
                return adultDetail ? true : false
            }        
        },
        methods: {
            ...mapMutations('auth',['setDataQueryParams','setDateInQueryParams','setCategoryQueryParam', 'setSubCategoryQueryParam','setDestinationInQueryParams']),
            ...mapMutations('shop',['removeProductInCart']),
            setDateQueryParams(date){
                this.setDateInQueryParams(date)    
                this.$router.replace({ query: {...this.queryParams} }).catch(() => {})            
            },       
            totalBreakdown(){                                
                const paxAdults = this.detailAdult ? parseFloat(this.detailAdult.pax) : 0
                const paxChildren = this.detailChild ? parseFloat(this.detailChild.pax) : 0
                const totalAdults = this.detailAdult ? (parseFloat ( this.detailAdult.saleprice ) * parseFloat (paxAdults)).toFixed(2): 0
                const totalChildren = this.detailChild ? (parseFloat (this.detailChild.saleprice) * parseFloat (paxChildren)).toFixed(2) : 0
                const totalGrant = toDecimal( parseFloat (totalAdults ) + parseFloat (  totalChildren))

                return { totalAdults, totalChildren, totalGrant}                
            }, 
            goToReserve(){      
				this.setDataQueryParams({...this.queryParams, idItem: this.queryParams.idItem })
				this.$router.push({ name:'item-detail', query: {...this.queryParams} })
			},  
            deleteItemInCart(){
                this.removeProductInCart( this.cart[0].uuid)
                setTimeout(() => {                    
                    this.$router.push({ name:'results', query: {...this.queryParams} })
                }, 1500);
            },  
            imgAlternativo( event ){
                event.target.src = require('@/assets/images/default.jpg')
            },   
        },          
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/summary-item-checkout.scss";  


</style>